<template>
    <div class="scheme-item" @click="showInfo">
        <div class="thumb">
            <img :src="item.thumb">
            <div class="select cursor" @click.stop="select">
                <img src="/assets/images/scheme/select-active.png" v-if="item.active">
                <img src="/assets/images/scheme/select-default.png" v-else>
            </div>
            <div class="video">
                <img src="/assets/images/scheme/yes-video.png" v-if="item.video_id">
                <img src="/assets/images/scheme/no-video.png" v-else>
            </div>
        </div>
        <div class="content">
            <div class="name">{{ item.title }}</div>
            <div class="styles">
                <div class="style-item" v-for="style in item.atmosphere" :key="style.id">{{ style.name }}</div>
                <div class="style-item" v-for="style in item.occasion" :key="style.id">{{ style.name }}</div>
                <div class="style-item" v-for="style in item.season" :key="style.id">{{ style.name }}</div>
                <div class="style-item" v-for="style in item.styles" :key="style.id">{{ style.name }}</div>
            </div>
            <div class="price-row">
                <div class="price">
                    <span>¥</span>
                    <span>{{ item.price }}</span>
                </div>
                <div class="num">
                    <img src="/assets/images/scheme/icon-hot.png">
                    <span>已售{{0}}件</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "SchemeItem",
    props: {
        item:Object
    },
    mounted() {

    },
    data() {
        return {
            
        }
    },
    methods: {
        select(){
            this.$emit('select',this.item);
        },
        showInfo(){
            this.$emit('showInfo',this.item);
        }
    }
}
</script>

<style lang="less" scoped>
.cursor {
    cursor: pointer;
}
.scheme-item {
    width: 242px;
    background: #FFFFFF;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.06);
    margin-right: 12px;
    margin-bottom: 16px;
    cursor: default;
    // &:nth-child(4n){
    //     margin-right: 0;
    // }
    .thumb {
        width: 242px;
        height: 242px;
        background: #FAFAFA;
        padding: 16px;
        position: relative;
        img{
            width: 100%;
            height: 100%;
        }
        .select{
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0;
            bottom: 0;
            img{
                width: 16px;
                height: 16px;
            }
        }
        .video{
            position: absolute;
            top: 12px;
            left: 12px;
            img{
                width: 24px;
                height: 24px;
            }
        }
    }

    .content {
        padding: 12px;

        .name {
            color: #333333;
            font-size: 14px;
            font-weight: bold;
        }

        .styles {
            min-height: 54px;
            display: flex;
            flex-wrap: wrap;
            .style-item {
                padding: 0 4px;
                height: 19px;
                background: linear-gradient( rgba(255, 255, 255, 0) 0%, rgba(51, 51, 51, 0.1) 100%);
                border-radius: 2px;
                margin-top: 8px;
                margin-right: 8px;
                display: flex;
                align-items: center;
                color: #333333;
                font-size: 11px;
                img{
                    width: 12px;
                    height: 12px;
                    margin-right: 8px;
                }
            }
        }

        .price-row{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 12px;
            .price{
                span{
                    color: #333333;
                    &:nth-child(1){
                        font-size: 12px;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        font-weight: bold;
                    }
                }
            }
            .num{
                color: rgba(51,51,51,0.4);
                font-size: 12px;
                display: flex;
                align-items: center;
                img{
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}
</style>