<template>
    <div class="search-header">
        <div class="title-box flex">
            <div class="title">搭配列表</div>
            <div class="form-box flex">

                <div class="scheme-btn flex cursor" @click="gotoDeck">
                    <img src="/assets/images/scheme/icon-plus.png">
                    <span>开始搭配</span>
                </div>
            </div>
        </div>

        <div class="search-row flex">
            <div class="search-item flex cursor" @click="showChange(1)">
                <div class="img flex">
                    <img src="/assets/images/scheme/icon-match.png">
                </div>
                <span class="label">搭配风格：</span>
                <div class="style-box flex">
                    <div class="name">{{ styleName }}</div>
                    <img src="/assets/images/scheme/icon-bottom.png">
                </div>
                <div class="style-list" style="width: 416px;" v-if="show1">
                    <div class="style-item" v-for="item in list[0].list" :key="item.id" @click.stop="selectStyle(item,1)">
                        <div class="bg">
                            <img :src="item.thumb">
                            <img class="active" src="/assets/images/deck/style-select.png" v-if="item.id == style.id">
                        </div>
                        <div class="name">{{ item.name.split('style')[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="search-item flex cursor" @click="showChange(2)">
                <div class="img flex">
                    <img src="/assets/images/scheme/icon-style.png">
                </div>
                <span class="label">风格氛围感：</span>
                <div class="style-box flex">
                    <div class="name">{{ atmosphere.name || '请选择' }}</div>
                    <img src="/assets/images/scheme/icon-bottom.png">
                </div>
                <div class="style-list" style="width: 520px;" v-if="show2">
                    <div class="style-item" v-for="item in list[1].list" :key="item.id" @click.stop="selectStyle(item,2)">
                        <div class="bg">
                            <img :src="item.thumb">
                            <img class="active" src="/assets/images/deck/style-select.png"
                                v-if="item.id == atmosphere.id">
                        </div>
                        <div class="name">{{ item.name }}</div>
                    </div>
                </div>
            </div>
            <div class="search-item flex cursor" @click="showChange(3)">
                <div class="img flex">
                    <img src="/assets/images/scheme/icon-site.png">
                </div>
                <span class="label">适合场合：</span>
                <div class="style-box flex">
                    <div class="name">{{ occasion.name || '请选择' }}</div>
                    <img src="/assets/images/scheme/icon-bottom.png">
                </div>
                <div class="style-list" style="width: 224px;" v-if="show3">
                    <div class="style-item" v-for="item in list[2].list" :key="item.id" @click.stop="selectStyle(item,3)">
                        <div class="bg">
                            <img :src="item.thumb">
                            <img class="active" src="/assets/images/deck/style-select.png"
                                v-if="item.id == occasion.id">
                        </div>
                        <div class="name">{{ item.name }}</div>
                    </div>
                </div>
            </div>
            <div class="search-item flex cursor" @click="showChange(4)">
                <div class="img flex">
                    <img src="/assets/images/scheme/icon-season.png">
                </div>
                <span class="label">季节：</span>
                <div class="style-box flex">
                    <div class="name">{{ season.name || '请选择' }}</div>
                    <img src="/assets/images/scheme/icon-bottom.png">
                </div>
                <div class="style-list" style="width: 224px;" v-if="show4">
                    <div class="style-item" v-for="item in list[3].list" :key="item.id" @click.stop="selectStyle(item,4)">
                        <div class="bg">
                            <img :src="item.thumb">
                            <img class="active" src="/assets/images/deck/style-select.png" v-if="item.id == season.id">
                        </div>
                        <div class="name">{{ item.name }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="search-row flex">

            <div class="gender-btn flex cursor" @click="genderChange">
                <img v-if="gender == 1" src="/assets/images/scheme/icon-nan.png">
                <img v-if="gender == 2" src="/assets/images/scheme/icon-nv.png">
                <span v-if="gender == 1">男士</span>
                <span v-if="gender == 2">女士</span>
                <img src="/assets/images/scheme/icon-gender-select.png">
            </div>

            <div class="search-item flex">
                <img class="icon" src="/assets/images/scheme/icon-search.png">
                <span class="label">关键词:</span>
                <input type="text" v-model="keyWords" placeholder="搜索搭配相关关键词">
            </div>

            <div class="search-item flex">
                <img class="icon" src="/assets/images/scheme/icon-time.png">
                <span class="label">创建时间:</span>
                <div class="time">
                    <div>
                        <a-range-picker :value="dateValue" @change="onChange" class="cursor" />
                    </div>
                    <img class="icon" src="/assets/images/scheme/icon-date.png">
                </div>
            </div>

            <div class="search-item flex">
                <img class="icon" src="/assets/images/scheme/icon-tag.png">
                <span class="label">价格区间:</span>
                <div class="price-area flex">
                    <input class="price" type="text" v-model="priceStart">
                    <div class="line"></div>
                    <input class="price" type="text" v-model="priceEnd">
                </div>
            </div>

            <!-- <div class="search-item flex">
                <img class="icon" src="/assets/images/scheme/icon-goods.png">
                <span class="label">来源:</span>
                <div class="from flex">
                    <div class="from-item flex">
                        <div class="select"></div>
                        <div class="from-label">全部自营</div>
                    </div>
                    <div class="from-item flex" style="margin-right: 0;">
                        <div class="select"></div>
                        <div class="from-label">仅看有货</div>
                    </div>
                </div>
            </div> -->

        </div>
        <div class="search-row flex">
            <div></div>
            <div class="search-item flex">
                <div class="search-btn reset-btn cursor" @click="reset">重置</div>
                <div class="search-btn cursor" @click="search">搜索</div>
            </div>
        </div>
        <div class="foot-line"></div>
    </div>
</template>

<script>
import api from "@/repo/api";
export default {
    name: 'SearchHeader',
    data() {
        return {
            show1: false,
            show2: false,
            show3: false,
            show4: false,
            list1: [],
            list2: [],
            list3: [],
            list4: [],
            dateValue:[],
            gender: 2,
            keyWords: '',
            atStrat: '',
            atEnd: '',
            priceStart: '',
            priceEnd: '',
            style: {},
            atmosphere: {},
            occasion: {},
            season: {},
        }
    },
    computed: {
        styleName() {
            if (this.style.name) {
                return this.style.name.split('style')[0]
            } else {
                return '请选择'
            }
        },
        list() {
            let arr1 = [];
            let arr2 = [];
            let arr3 = [];
            let arr4 = [];

            this.list1.forEach((item) => {
                if (item.gender == this.gender) {
                    arr1.push(item);
                }
            })

            this.list2.forEach((item) => {
                if (item.gender == this.gender || item.gender == 0) {
                    if (this.gender == 1) {
                        item.thumb = item.extra.thumb || item.extra.man_thumb;
                    } else {
                        item.thumb = item.extra.thumb || item.extra.woman_thumb;
                    }
                    arr2.push(item);
                }
            })

            this.list3.forEach((item) => {
                if (item.gender == this.gender || item.gender == 0) {
                    if (this.gender == 1) {
                        item.thumb = item.extra.thumb || item.extra.man_thumb;
                    } else {
                        item.thumb = item.extra.thumb || item.extra.woman_thumb;
                    }
                    arr3.push(item);
                }
            })

            this.list4.forEach((item) => {
                if (item.gender == this.gender || item.gender == 0) {
                    if (this.gender == 1) {
                        item.thumb = item.extra.thumb || item.extra.man_thumb;
                    } else {
                        item.thumb = item.extra.thumb || item.extra.woman_thumb;
                    }
                    arr4.push(item);
                }
            })

            return [
                {
                    label: '搭配风格',
                    list: arr1,
                },
                {
                    label: '风格氛围感',
                    list: arr2,
                },
                {
                    label: '适合场合',
                    list: arr3,
                },
                {
                    label: '适合季节',
                    list: arr4,
                },
            ]
        },
        // styles() {
        //     let ids = [];
        //     this.list[0].list.forEach((item) => {
        //         if (item.active) {
        //             ids.push(item.id);
        //         }
        //     })
        //     return ids;
        // },
        // atmosphere() {
        //     let ids = [];
        //     this.list[1].list.forEach((item) => {
        //         if (item.active) {
        //             ids.push(item.id);
        //         }
        //     })
        //     return ids;
        // },
        // occasion() {
        //     let ids = [];
        //     this.list[2].list.forEach((item) => {
        //         if (item.active) {
        //             ids.push(item.id);
        //         }
        //     })
        //     return ids;
        // },
        // season() {
        //     let ids = [];
        //     this.list[3].list.forEach((item) => {
        //         if (item.active) {
        //             ids.push(item.id);
        //         }
        //     })
        //     return ids;
        // },
    },
    mounted() {
        this.getAttributes();
    },
    methods: {
        reset() {
            this.gender = 2;
            this.keyWords = '';
            this.atStrat = '';
            this.atEnd = '';
            this.priceStart = '';
            this.priceEnd = '';
            this.style = {};
            this.atmosphere = {};
            this.occasion = {};
            this.season = {};
            this.dateValue = [];
            this.search();
        },
        search() {
            let params = {
                style: this.style.id || 0,
                gender: this.gender,
                keywords: this.keyWords,
                created_at_start: this.atStrat,
                created_at_end: this.atEnd,
                price_start: this.priceStart,
                price_end: this.priceEnd,
                atmosphere: this.atmosphere.id || 0,
                occasion: this.occasion.id || 0,
                season: this.season.id || 0,
            }
            console.log(params);
            
            this.$emit("searchFun", params);
        },
        onChange(arr) {
            console.log(this.$moment(arr[0]).format('yyyy-MM-DD'));
            console.log(this.$moment(arr[1]).format('yyyy-MM-DD'));
            this.dateValue = arr;
            this.atStrat = this.$moment(arr[0]).format('yyyy-MM-DD');
            this.atEnd = this.$moment(arr[1]).format('yyyy-MM-DD');
        },
        genderChange() {
            this.gender = this.gender == 1 ? 2 : 1;
        },
        showOver(idx) {
            this['show' + idx] = true;
        },
        showOut(idx) {
            this['show' + idx] = false;
        },
        styleChange(item) {
            this.$set(item, 'active', !item.active);
        },
        selectStyle(item,idx){
            if (idx == 1) {
                if (this.style.id == item.id) {
                    this.style = {};
                }else{
                    this.style = item;
                }
            }
            if (idx == 2) {
                if (this.atmosphere.id == item.id) {
                    this.atmosphere = {};
                }else{
                    this.atmosphere = item;
                }
            }
            if (idx == 3) {
                if (this.occasion.id == item.id) {
                    this.occasion = {};
                }else{
                    this.occasion = item;
                }
            }
            if (idx == 4) {
                if (this.season.id == item.id) {
                    this.season = {};
                }else{
                    this.season = item;
                }
            }
            this.showChange(idx);
        },
        showChange(idx) {
            this['show' + idx] = !this['show' + idx];
            let arr = [1, 2, 3, 4];
            for (let i in arr) {
                if (arr[i] != idx) {
                    this['show' + arr[i]] = false;
                }
            }
        },
        getAttributes() {
            api.get('/ast-scheme/attributes', (data) => {

                if (!data.data) return;

                this.filterStyle(data.data.attributes);
            })
        },
        gotoDeck(){
            this.$router.push('/ast/decks')
        },
        filterStyle(list) {
            // 风格
            let list1 = [];
            // 氛围
            let list2 = [];
            // 场合
            let list3 = [];
            // 季节
            let list4 = [];

            list.forEach((item) => {
                if (item.parent_id == 10086) {
                    list1.push(item);
                }
                if (item.parent_id == 8) {
                    list2.push(item);
                }
                if (item.parent_id == 5) {
                    list3.push(item);
                }
                if (item.parent_id == 7) {
                    list4.push(item);
                }
            })

            this.list1 = list1;
            this.list2 = list2;
            this.list3 = list3;
            this.list4 = list4;
        },
    }
}
</script>

<style lang="less" scoped>
.cursor {
    cursor: pointer;
}
.flex {
    display: flex;
    align-items: center;
}

/deep/ .ant-input {
    border: none;
}

.search-header {
    .search-row {
        margin-top: 16px;

        .gender-btn {
            width: 92px;
            height: 40px;
            background: rgba(51, 51, 51, 0.04);
            margin-right: 16px;
            justify-content: center;

            img {
                width: 16px;
                height: 16px;
                margin: 0 4px;
            }

            span {
                color: #333333;
                font-size: 14px;
                line-height: 20px;
            }
        }

        .icon {
            width: 24px;
            height: 24px;
        }

        .img {
            height: 16px;

            img {
                height: 100%;
            }
        }

        .label {
            color: #333333;
            font-size: 16px;
            font-weight: bold;
            margin-left: 4px;
            margin-right: 8px;
            cursor: default;
        }

        .search-item {
            margin-right: 12px;
            position: relative;

            &:nth-last-child(1) {
                margin-right: 0;
            }

            .style-list {
                background: #FFFFFF;
                box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
                border-radius: 8px;
                position: absolute;
                top: 48px;
                left: 0;
                z-index: 999;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding: 16px;

                .style-item {

                    margin-right: 16px;
                    margin-bottom: 16px;
                    // cursor: pointer;

                    .bg {
                        position: relative;

                        img {
                            width: 80px;
                            height: 80px;
                            border-radius: 50%;
                        }

                        .active {
                            width: 80px;
                            height: 80px;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }

                    .name {
                        color: #333333;
                        font-size: 14px;
                        text-align: center;
                        margin-top: 12px;
                    }
                }
            }

            .style-box {
                height: 40px;
                background: #F7F7F7;
                cursor: pointer;

                .name {
                    width: 74px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    color: #333333;
                    font-size: 14px;
                    margin: 0 12px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                }
            }

            .from {
                .from-item {
                    margin-right: 8px;

                    .select {
                        width: 12px;
                        height: 12px;
                        border-radius: 2px;
                        border: 1px solid #333333;
                    }

                    .from-label {
                        color: #333333;
                        font-size: 14px;
                        line-height: 20px;
                        margin-left: 8px;
                    }
                }
            }

            .price-area {
                .price {
                    width: 40px;
                    height: 40px;
                    background: #FFFFFF;
                    border: 1px solid #EEEEEE;
                    text-align: center;
                }

                .line {
                    width: 10px;
                    height: 1px;
                    background: #333333;
                    margin: 0 8px;
                }
            }

            .time {
                width: 240px;
                height: 40px;
                background: #FFFFFF;
                border: 1px solid #EEEEEE;
                display: flex;
                align-items: center;

                div {
                    flex: 1;
                }

                img {
                    width: 16px;
                    height: 16px;
                    margin: 0 8px;
                }
            }

            input {
                width: 154px;
                height: 40px;
                background: #FFFFFF;
                border: 1px solid #EEEEEE;
                color: #333333;
                padding: 10px 12px;
                box-sizing: border-box;
                margin-right: 8px;
            }

            .reset-btn {
                margin-right: 16px;
            }

            .search-btn {
                width: 52px;
                height: 40px;
                line-height: 40px;
                background: #333333;
                text-align: center;
                color: #FFFFFF;
                font-size: 14px;
            }
        }
    }

    .title-box {
        width: 100%;
        justify-content: space-between;

        .title {
            color: #333333;
            font-size: 20px;
            font-weight: bold;
        }

        .form-box {
            img {
                width: 16px;
                height: 16px;
                margin: 0 4px;
            }

            .scheme-btn {
                width: 100px;
                height: 40px;
                background: linear-gradient(96deg, #5F5F5F 0%, #333333 100%);
                justify-content: center;

                span {
                    color: #FFFFFF;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }

    .foot-line {
        width: 100%;
        height: 1px;
        background: #EEEEEE;
        margin: 16px 0;
    }
}
</style>