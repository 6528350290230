<template>
    <div class="scheme-info" v-if="show">
        <div class="info-container">
            <div class="close" @click="hide">
                <img src="/assets/images/scheme/info-close.png">
            </div>
            <div class="info-left">
                <div class="thumb">
                    <img :src="currentImage">
                    <img class="type" src="/assets/images/scheme/info-icon3.png" v-if="detail.is_delete">
                    <img class="type" src="/assets/images/scheme/info-icon1.png" v-else-if="detail.status == 1">
                    <img class="type" src="/assets/images/scheme/info-icon2.png" v-else>
                </div>
                <div class="swiper-box">
                    <swiper class="swiper" ref="mySwiper" :options="swiperOption">
                        <swiper-slide class="slide" v-for="(url) in imageList" :key="url">
                            <img class="swiper-img" :src="url">
                        </swiper-slide>
                        <div class="btn swiper-button-prev" slot="button-prev" @click="prev">
                            <img src="/assets/images/scheme/info-icon-left.png">
                        </div>
                        <div class="btn swiper-button-next" slot="button-next" @click="next">
                            <img src="/assets/images/scheme/info-icon-right.png">
                        </div>
                    </swiper>
                </div>
            </div>
            <div class="info-right">
                <div class="title">{{ detail.title }}</div>
                <div class="price">
                    <span>¥</span>
                    <span>{{ detail.price }}</span>
                </div>
                <div class="info">
                    <img class="bg" src="/assets/images/scheme/info-bg.png">
                    <div class="content">
                        <div class="info-row">
                            <div class="label">-适宜着装场合：</div>
                            <div class="styles">
                                <div class="style" v-for="style in detail.occasion" :key="style.id">{{
                                    style.name.split('style')[0] }}</div>
                            </div>
                        </div>
                        <div class="info-row">
                            <div class="label">-适宜风格人群：</div>
                            <div class="styles">
                                <div class="style" v-for="style in detail.styles" :key="style.id">{{
                                    style.name.split('style')[0] }}</div>
                            </div>
                        </div>
                        <div class="info-row">
                            <div class="label">-搭配话题：</div>
                            <div class="styles">
                                <div class="style" v-for="style in detail.atmosphere" :key="style.id">{{
                                    style.name.split('style')[0] }}</div>
                            </div>
                        </div>
                        <div class="info-row">
                            <div class="label">-季节：</div>
                            <div class="styles">
                                <div class="style" v-for="style in detail.season" :key="style.id">{{
                                    style.name.split('style')[0] }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="code-box">
                    <div class="qr-code">
                        <img :src="qrCodeUrl">
                    </div>
                    <div class="tips">扫码快速分享用户</div>
                </div>
                <div class="btn-box">
                    <div class="del" @click="showModal = true">删除</div>
                    <div class="edit" @click="gotoEdit">编辑</div>
                </div>
            </div>
        </div>
        <a-modal v-model="showModal" title="提示" centered @ok="schemeChange">
            <p>确定要删除该搭配吗？</p>
        </a-modal>
    </div>
</template>

<script>

import api from "@/repo/api";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
    name: "scheme-info",
    props:{
        delType:String
    },
    components: {
        Swiper, SwiperSlide
    },
    data() {
        const _this = this;
        return {
            show: false,
            showModal:false,
            detail: {},
            qrCodeUrl:'',
            currentImage: '',
            swiperOption: {
                slidesPerView: 'auto',
                spaceBetween: 8,
                centeredSlides: true,
                slideToClickedSlide: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                on: {
                    slideChange() {
                        console.log(_this.imageList[this.activeIndex]);
                        _this.currentImage = _this.imageList[this.activeIndex];
                    }
                }
            },
        }
    },
    computed: {
        imageList() {
            return [
                this.detail.thumb,
                ...this.detail.goods.map(goods => goods.thumb_image || goods.white_image),
            ]
        },
    },
    mounted() {

    },
    methods: {
        open(id) {
            this.detail = {};
            this.$loading.show();
            this.getDetail(id);
        },
        hide() {
            this.show = false;
        },
        prev() {
            this.$refs.mySwiper.$swiper.slidePrev();
        },
        next() {
            this.$refs.mySwiper.$swiper.slideNext();
        },
        gotoEdit() {
            this.$router.push(`/ast/decks?outfit_id=${this.detail.id}&type=${this.detail.type}`)
        },
        schemeChange() {

            this.$loading.show();
            let data = {
                ids:[this.detail.id], do: this.delType
            }
            console.log(data);
            
            api.post('/ast-scheme/change-scheme', data, (res) => {
                this.$loading.hide();
                this.showModal = false;
                if (res.code == 0) {
                    this.hide();
                    this.$emit('getList');
                }

            })
        },
        getDetail(id) {
            api.get('/ast-scheme/scheme-detail', { id }, (res) => {
                this.$loading.hide();
                if (res.code == 0) {
                    this.qrCodeUrl = this.$qrcode("packagesC/schemeDetail/schemeDetail?id="+res.data.id);
                    this.currentImage = res.data.thumb;
                    this.detail = res.data;
                    this.show = true;
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@mixin swiperButton() {
    font-size: 16px;
    color: white;
    width: 26px;
    height: 64px;
    background: rgba(0, 0, 0, 0.4);
    line-height: 64px;
    text-align: center;
}

.scheme-info {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;

    .info-container {
        width: 939px;
        background: #FFFFFF;
        padding: 24px;
        display: flex;
        position: relative;

        .close {
            padding: 24px;
            position: absolute;
            top: 0;
            right: 0;

            img {
                width: 16px;
                height: 16px;
            }
        }

        .info-left {
            margin-right: 24px;

            .thumb {
                width: 492px;
                height: 492px;
                padding: 40px;
                background: #F8F8F8;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                .type {
                    width: 100px;
                    height: 100px;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }

            .swiper-box {
                width: 492px;
                height: 64px;
                margin-top: 22px;
                overflow: hidden;
                position: relative;

                .swiper {
                    height: 64px;
                    width: 100%;

                    /deep/ .swiper-wrapper {
                        display: flex;
                        margin-left: -30%;
                        // justify-content: center;
                    }

                    .slide {
                        width: 64px;
                        height: 64px;
                        flex-shrink: 0;
                        border-radius: 8px;
                        background: #FFFFFF;
                        border: 1px solid #E6E6E6;
                        padding: 7px;
                    }

                    .swiper-img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }

                    .btn {
                        width: 26px;
                        height: 64px;
                        background: rgba(0, 0, 0, 0.4);
                        position: absolute;
                        top: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0;
                        &::after{
                            display: none;
                        }
                        img{
                            width: 20px;
                            height: 20px;
                        }
                    }

                    .swiper-button-prev {
                        border-radius: 8px 0px 0px 8px;
                        left: 0;
                    }

                    .swiper-button-next {
                        border-radius: 0px 8px 8px 0px;
                        right: 0;
                    }
                }
            }
        }

        .info-right {
            .btn-box {
                display: flex;
                align-items: center;
                margin-top: 44px;

                .del {
                    width: 92px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    border: 1px solid #F5606D;
                    color: #F5606D;
                    font-size: 14px;
                    margin-right: 16px;
                }

                .edit {
                    width: 92px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    background: #333333;
                    color: #FFFFFF;
                    font-size: 14px;
                }
            }

            .code-box {
                margin-top: 16px;

                .qr-code {
                    width: 120px;
                    height: 120px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }

                .tips {
                    color: #333333;
                    font-size: 14px;
                    margin-top: 12px;
                }
            }

            .title {
                color: #333333;
                font-size: 24px;
                font-weight: 500;
                line-height: 34px;
            }

            .price {
                color: #333333;
                margin-top: 12px;

                span {
                    &:nth-child(1) {
                        font-size: 20px;
                        margin-right: 4px;
                    }

                    &:nth-child(2) {
                        font-size: 32px;
                        font-weight: 500;
                        line-height: 45px;
                    }
                }
            }

            .info {
                width: 375px;
                height: 204px;
                margin-top: 16px;
                position: relative;

                .bg {
                    width: 100%;
                    height: 100%;
                }

                .content {
                    position: absolute;
                    left: 16px;
                    top: 64px;

                    .info-row {
                        display: flex;
                        align-items: center;
                        margin-bottom: 6px;
                        .label {
                            color: #999999;
                            font-size: 16px;
                            margin-right: 8px;
                        }

                        .styles {
                            display: flex;
                            align-items: center;

                            .style {
                                color: #333333;
                                font-size: 16px;
                                margin-right: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>